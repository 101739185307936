import { CatalogObject, Translation } from 'anketa-core';
import { KeycloakInstance } from 'keycloak-js';
import { AuthorizeComponent } from '../../_functions';
import { generateHeader } from './user';
import { generateActions } from './actions';

export class OnboardingCatalog extends CatalogObject {
  private static _instance: OnboardingCatalog;

  public static get instance(): OnboardingCatalog {
    if (!OnboardingCatalog._instance) {
      throw new Error(
        'OnboardingCatalog instance has not been created. Use createInstance(name) method first.'
      );
    }
    return OnboardingCatalog._instance;
  }

  public static createInstance(
    name: string,
    changeListener: () => void,
    authCtx: KeycloakInstance
  ): OnboardingCatalog {
    if (!OnboardingCatalog._instance) {
      OnboardingCatalog._instance = new OnboardingCatalog(name, changeListener, authCtx);
    }
    return OnboardingCatalog._instance;
  }

  constructor(name: string, changeListener: () => void, authCtx: KeycloakInstance) {
    const id: string = name.toLowerCase().replace(/ /g, '-');
    super(id, [new Translation('en', name), new Translation('de', name)]);

    const supportAdmin: boolean = AuthorizeComponent(authCtx, 'anketa-support')

    const header = generateHeader()
    this.addChild(header)

    this.addChild(generateActions(`onboarding_${id}`, changeListener, authCtx));

  }
}
