import {
  ComplexFactory,
  ComplexQuestion,
  Group,
  OpenQuestion,
  Row,
  Section,
  Translation,
} from 'anketa-core';

export const generateHeader = () => {
  const userSection = new Group('user', [
    new Translation('en', 'General data'),
    new Translation('de', 'Allgemeine Daten'),
  ]);

  const userRow = new Row('person', [
    new Translation('en', 'General data'),
    new Translation('de', 'Allgemeine Daten'),
  ]);

  const customer = new ComplexQuestion(
    'uservalues',
    [new Translation('en', 'Customer Contact'), new Translation('de', 'Kundenkontakt')],
    'User'
  );

  userRow.addChild(customer);
  userSection.addChild(userRow);

  return userSection;
};
