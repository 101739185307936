import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router';
import { useKeycloak } from './_functions/auth/keycloak';
import Questinary from './questinary';
import { NewsletterComponent } from './newletter';
import { AuthorizeItem } from './_functions/auth/authorize';
import { NewsletterLeave } from './newletter/leave';
import { AppointmentComponent } from './newletter/appointment';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { SupportQuestionarys } from './catalogs/onboarding/indes';

export const App = (): JSX.Element => {
  const authCtx = useKeycloak();

  const msalConfiguration = {
    auth: {
      clientId: 'e32feb06-de0d-4371-b895-36adca6c718b',
      authority: 'https://login.microsoftonline.com/8495880e-0ea4-433e-936a-8e183898c1af',
    },
  };

  const pca = new PublicClientApplication(msalConfiguration);

  return (
    <Routes>
      <Route path="/" element={<Questinary />} />
      <Route
        path="/support"
        element={
          <MsalProvider instance={pca}>
            <SupportQuestionarys />
          </MsalProvider>
        }
      />
      <Route
        path="/newsletter"
        element={
          <AuthorizeItem
            key={`router:newsletter`}
            ctx={authCtx.keycloak}
            roleName={`anketa-newsletter-admin`}
          >
            <NewsletterComponent />
          </AuthorizeItem>
        }
      />
      <Route
        path="/public/newsletter/:newsletter/unsubscribe/:userid"
        element={<NewsletterLeave />}
      />
      <Route
        path="/public/newsletter/:newsletter/appointment/:userid"
        element={<AppointmentComponent />}
      />
    </Routes>
  );
};
