import React, { useEffect } from 'react';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from '@azure/msal-react';
import { EuiButton, EuiEmptyPrompt, EuiPageTemplate } from '@elastic/eui';
import QuestinarySupport from './questinary';

export const SupportQuestionarys = () => {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const login = async () => {
    try {
      await instance.loginPopup({
        scopes: ['User.Read'],
      });
    } catch (error) {
      console.error('Login error:', error);
    }
  };

  const acquireToken = async () => {
    if (!isAuthenticated) return;
    try {
      const accounts = instance.getAllAccounts();
      if (accounts.length > 0) {
        const tokenResponse = await instance.acquireTokenSilent({
          scopes: ['User.Read'],
          account: accounts[0],
        });
        console.log('Token acquired:', tokenResponse.accessToken);
      }
    } catch (error: any) {
      if (error instanceof InteractionRequiredAuthError) {
        try {
          const tokenResponse = await instance.acquireTokenPopup({
            scopes: ['User.Read'],
          });
          console.log('Token acquired via popup:', tokenResponse.accessToken);
        } catch (popupError) {
          console.error('Token acquisition via popup failed:', popupError);
        }
      } else {
        console.error('Token acquisition error:', error);
      }
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      acquireToken();
    } else {
      instance.loginRedirect();
    }
  }, [isAuthenticated]);

  return (
    <div>
      <AuthenticatedTemplate>
        <QuestinarySupport />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <EuiPageTemplate
          template="centeredContent"
          pageContentProps={{
            color: 'warning',
            role: null,
          }}
        >
          <EuiEmptyPrompt
            iconType="offline"
            title={<h2>Anmeldung benötig</h2>}
            body={
              <>
                <p>Sie müssen sich bei Microsoft anmelden um fortzufahren!</p>
              </>
            }
            actions={
              <EuiButton onClick={login} color="primary" fill>
                Login
              </EuiButton>
            }
          />
        </EuiPageTemplate>
      </UnauthenticatedTemplate>
    </div>
  );
};
